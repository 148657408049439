import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Login } from './pages/Login';
import { Dashboard } from './components/Dashboard';
import { Documents } from './pages/Documents';
import { Landing } from './pages/Landing';
import './App.css';

let lng = localStorage.getItem('language')
if( lng == null )
  lng = navigator.language || navigator.userLanguage
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          error_request: 'Error requesting to server',
          documents: 'Documents',
          ctqp_chatbot: 'FDM Agent',
          ctqp_title: 'FDM Agent',
          by_alfka: 'By Alfka',
          ctqp_description: 'Type in your inquiries and the agent will respond with helpful information',
          terms_and_conditions: 'Terms And Conditions',
          powered_by: 'Powered by Alfka Inc.',
          placeholder: 'Ask me anything',
          placeholder_username: 'Username',
          placeholder_password: 'Password'
        }
      },
    },
    lng: lng, // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const App = () => {
  return <Router>
            <Routes>              
              <Route path="/" element={<Login />} />
              <Route path="/landing" element={<Landing />} />
              <Route path="/dashboard" element={<Dashboard />}>
                <Route path="" element={<Documents />} />
              </Route>
            </Routes>
        </Router>
}

export default App;
