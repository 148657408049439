import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from '../assets/img/Alka_Logo.png';
import { useTranslation } from "react-i18next";

export const Login = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showWrongCredentials, setWrongCredentials] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onLogin = async () => {
    setLoading(true);
    try{
      // Wrong Credentials
      setWrongCredentials(false);
      // Login
      const response = await axios.post(`/api/token/`, { email, password})

      // Store user
      localStorage.setItem('user', JSON.stringify(response.data) );
      
      // Navigate to dashboard
      navigate("/landing");
    }catch(e){
      console.log("login failed")
      // wrong credentials
      setWrongCredentials(true)
    }
    setLoading(false);
  }


  const onKeyUp = (e) => {
    if(e.key === 'Enter') onLogin()    
  }

  return <main className="d-flex vh-100 justify-content-center align-items-center">

    <div>
      <div className='bg-white d-flex justify-content-center align-items-center p-3' style={{ height: "150px"}}>
        <img src={logo}  className='h-100' alt=''/>
        <h1 className="fw-bold ms-3" style={{ color: 'black'}}>{t('ctqp_title')}</h1>
      </div>
      <div className="mb-3">
        <input type="email" className="form-control" placeholder={t('placeholder_username')}
          onChange={(e) => setEmail(e.target.value)}  onKeyUp={(e) => onKeyUp(e)}/>
      </div>
      <div className="mb-3">
        <input type="password" className="form-control" placeholder={t('placeholder_password')} 
          onChange={(e) => setPassword(e.target.value)} onKeyUp={(e) => onKeyUp(e)}/>
      </div>
      <button type="button" className="ctqp-button btn btn-primary w-100 d-flex justify-content-center align-items-center"
        onClick={() => onLogin()}>
        <span>Login</span>
        {loading?
        <div className="spinner-border spinner-border-sm ms-2" role="status" />
        :null}
      </button>

      {showWrongCredentials?
      <div class="alert alert-danger mt-3" role="alert">
        <div>Something goes wrong</div>
        <small>An error occurred. Please try again later</small>
      </div>
      :null}



    </div>
  </main>
}