import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGears } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react';
// import { getHeader } from '../common/utils';
import { useLocation, useNavigate } from "react-router-dom";
// import routes from '../common/routes'
// import "./Dashboard.css"
import { ToastContainer, Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import AnimateHeight from 'react-animate-height';
import { Outlet } from "react-router-dom";
import axios from 'axios'
// import { setUser } from '../redux'
// import { useDispatch } from 'react-redux'


export const Dashboard = (props) =>{
  const { t } = useTranslation()
  const location = useLocation();
  // const dispatch = useDispatch();
  // const page = useSelector(state => state.main.page)
  const navigate = useNavigate();
  const [ me, setMe ] = useState({ first_name: '', last_name: '', logged: false})


  // const onSectionChange = (page) => {
  //   navigate("/home");
  //   //dispatch( setPage( page ) ) 
  // }

  // const getInitInfluxDBHeight = () => { 
  //   const locationLocal = location.pathname.replace(/\/$/, "");
  //   if( locationLocal === routes.influxdb_server.url || 
  //     locationLocal === routes.influxdb_credential.url) return 'auto';
  //   return 0
  // }
  // const [showInfluxDBMenu, setShowInfluxDBMenu] = useState( getInitInfluxDBHeight() );

  // const getInitCompanyHeight = () => { 
  //   const locationLocal = location.pathname.replace(/\/$/, "");
  //   if( locationLocal === routes.company.url || 
  //     locationLocal === routes.company_site.url) return 'auto';
  //   return 0
  // }
  // const [showCompanyMenu, setShowCompanyMenu] = useState( getInitCompanyHeight() );

  // useEffect ( () => {
  //   ( async () => {
  //     try{
  //       const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/users/me/`, { headers: getHeader() });
  //       setMe({...response.data, logged: true})
  //       dispatch( setUser( response.data ) ) 
  //     }catch(e){
  //       // toast.success(t('user_saved'))   
  //       console.log("error ", e)
  //       toast.error(t('whoami_failed'))
  //       setTimeout( () => { navigate('/') }, 3000)
  //     }
  //   })();
  // }, [])

  // const getTitle = () => {
  //   const locationLocal = location.pathname.replace(/\/$/, "");
  //   if( locationLocal === routes.user.url) return t( routes.user.title )
  //   if( locationLocal === routes.company.url) return t( routes.company.title )
  //   if( routes.company_site.regex.test(locationLocal) ) return t( routes.company_site.title )
  //   if( locationLocal === routes.influxdb_server.url) return t( routes.influxdb_server.title )
  //   if( routes.influxdb_credential.regex.test(locationLocal)) return t( routes.influxdb_credential.title )
  //   if( locationLocal === routes.data_hub.url) return t( routes.data_hub.title )
  //   if( routes.sensor.regex.test(locationLocal) ) return t( routes.sensor.title )
  //   return ""
  // }

  // const getSelected = () => {
  //   const locationLocal = location.pathname.replace(/\/$/, "");
  //   if( locationLocal === routes.user.url) return PAGE.USER
  //   if( locationLocal === routes.company.url) return PAGE.COMPANY
  //   if( routes.company_site.regex.test(locationLocal) ) return PAGE.COMPANY
  //   if( locationLocal === routes.influxdb_server.url) return PAGE.INFLUXDB_SERVER
  //   // if( locationLocal === routes.influxdb_credential.url) return PAGE.INFLUXDB_CREDENTIALS
  //   if( routes.influxdb_credential.regex.test(locationLocal) ) return PAGE.INFLUXDB_SERVER
  //   if( locationLocal === routes.data_hub.url) return PAGE.DATA_HUB
  //   if( routes.sensor.regex.test(locationLocal) ) return PAGE.DATA_HUB
  //   return ""
  // }

  const onClickUser = (e) => {
    e.stopPropagation()
    //setShowSidebarUser(true)
  }
  const onClickLogout = () => {
    // Store user
    localStorage.removeItem('user');

    // Navigate to dashboard
    navigate("/login/");
  }

  return <div className="d-flex vh-100">
      <div className="h-100  border-end border-grey d-flex flex-column" 
        style={{ background: '#00305f', color: 'white', width: '300px', maxWidth:'25%'}}>        
        <div className='bg-white d-flex justify-content-center align-items-center p-3' style={{ height: "70px"}}>
          <h4 className="fw-bold" style={{ color: 'black'}}>CTQP CHATBOT</h4>
        </div>
        <div className='d-flex justify-content-between flex-column p-4 flex-grow-1'>

          <div>
            <div role="button" className={`w-100 fw-bold`} >
              <FontAwesomeIcon className="me-3 cursor-pointer" icon={faGears} />
              <span>{t('documents')}</span>
            </div>
          </div>

          <button type="button" className="btn btn-secondary w-100" onClick={() => onClickLogout()}>
            Logout
          </button>

        </div>
      </div>
      <div className='flex-grow-1 d-flex flex-column'>
        <nav className="d-flex justify-content-between w-100" style={{ background: '#6c757d', height: '70px', color: '#DDD'}}>
          <div className='d-flex justity-content-center align-items-center ms-3 fw-bold'>
            <h5 style={{ margin: 0 }}>Dashboard</h5>
          </div>
          <div className='d-flex align-items-center justify-content-end'>
            <div className="h-100 pt-2 pb-2 me-3 cursor-pointer d-flex align-items-center">
              <div role="button" style={{ color: '#DDD'}} 
                onClick={(e) => onClickUser(e)}>Welcome {me.first_name} {me.last_name}!
              </div> 
            </div>
          </div>
        </nav>
        <div className='p-5 flex-grow-1 h-100 standalone-table-container' style={{ overflow: 'auto', height: '0px', minHeight: '80%'}}>
          <Outlet />
        </div>
      </div>
    
      
      <ToastContainer 
          position="bottom-right"
          transition={Slide} 
          autoClose={1000}
          closeButton={false}
          theme="colored"
          rtl />

  </div>
}