import React, { useState,useRef, useEffect } from 'react';
import logo from '../assets/img/Alka_Logo.png';
import sendingImg from '../assets/img/send.svg';
import userLogo from '../assets/img/profile-user.svg';
import threeDots from '../assets/img/dots.gif';
import './Landing.css';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

// import useDocumentHeight from './useDocumentHeight'
import Markdown from 'https://esm.sh/react-markdown@9'

axios.defaults.baseURL = 'https://chatbot-ctqp.duckdns.org';
// Add a request interceptor
axios.interceptors.request.use(function (config) {

  // Store user
  const user = localStorage.getItem('user');
  if (user){
    const userJson = JSON.parse(user);
    config.headers.Authorization = `Bearer ${userJson.access}`;
  }
  // const token = store.getState().session.token;
  // 
   
  return config;
});




const AUTHOR = { ME: 'ME', BOT: 'BOT' }
export const Landing = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('')
  const [messageList, setMessageList] = useState([
    // {author: AUTHOR.BOT, message: 'hello'},
  ])
  const [loading, setLoading] = useState(false)
  const session = useRef(null);
  const messageListContainer = useRef()
  const navigate = useNavigate();

  const onKeyDownMessage = (e) => {    
    if (e.key === 'Enter') {
      onSend(message)
    }
  }
  const onSend = async (messageLocal) => {
    try{
      // Do not send empty messages
      if(messageLocal.length === 0) return

      // Proceed
      const messageListLocal = JSON.parse(JSON.stringify(messageList))
      messageListLocal.push({message: messageLocal, author: AUTHOR.ME})
      setMessageList([ ...messageListLocal ])
      setMessage('')

      // Simulate loading
      setLoading(true)

      if( session.current == null ){
        session.current = uuidv4()
      }

      // Request for response
      const response = await axios.post(`/api/message/${session.current}/`, { request_message: messageLocal })
      let messageBotLocal = response.data.response[0];
      // messageBotLocal = messageBotLocal.replaceAll('\n', "<br></br>");
      messageListLocal.push({message: messageBotLocal, author: AUTHOR.BOT})
      setMessageList([ ...messageListLocal ])
      setLoading(false)


      // setTimeout( () => {
      //   setLoading(false)
      //   // Echo message
      //   messageListLocal.push({message: "BOT - " + messageLocal, author: AUTHOR.BOT})
      //   setMessageList([ ...messageListLocal ])
      // }, 2000)
    }catch(e){
      console.error(e)
      toast.error( t('error_request') )
      setTimeout( () => {
        navigate("/");
      }, 2000)
    }

  }

  const onLogout = () => {
    localStorage.removeItem('user');
    navigate("/");
  }

  useEffect(() => {
    if( messageListContainer.current == null ) return;
    const scrollHeight = messageListContainer.current.scrollHeight;
    const height = messageListContainer.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    messageListContainer.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;    
  }, [messageList])

  useEffect( () => {
    window.addEventListener("beforeunload", async (ev) => {
      // ev.preventDefault()
      try{
        fetch(`/close/${session.current}`, {
          method:'get',
          keepalive: true // this is important!
        })
      }catch(e){
        ev.preventDefault()
      }
      return ""
    });
  }, [])

  return <main className='main'>    
          <header className='ctqp-header ctqp-shadow'>
            <div style={{ borderRadius: '50%', overflow: 'hidden'}}><img src={logo} alt="" style={{ width: "40px" }}/></div>
            <div className='d-flex align-items-center justify-content-center h-100 fw-bold' 
              style={{ padding: '0.5em 0 0.5em 0', width: '10%'}}>
              {/* <img src={superRealLogo} height="30" alt=""/> */}
              <div style={{ padding: '1px 0'}}>
                <div style={{ width: '2px', height: '30px', background: 'black', margin: '0 0.5em'}}></div>        
              </div>
              <span className='d-flex justify-conten-center align-items-center me-3'>FDM Agent</span>
              <button className='ctqp-button btn btn-primary w-100 d-flex justify-content-center align-items-center'
              onClick={() => onLogout()}>
                Logout
              </button>
            </div>
          </header>    
          <section className='flex-grow-1'>
            {messageList.length===0 && 
            <div className='ctqp-title-container '>
              <div className='text-center'>
                <div style={{ borderRadius: '50%', overflow: 'hidden', width: '80px', margin: '0 auto'}}>
                  <img src={logo} alt="" height={80}/>
                </div>
              </div>
              <div className='ctqp-title'>{t('ctqp_chatbot')}</div>
              <div className='ctqp-subtitle'>{t('by_alfka')}</div>
              <div className='ctqp-description'>{t('ctqp_description')}</div>
            </div>
            }

            {messageList.length>0 && 
            <div ref={messageListContainer} className='ctqp-message-list-container'>
              {messageList.map( (item, idx) => {
                const logoAuthor = item.author === AUTHOR.BOT?logo:userLogo;
                const nameAuthor = item.author === AUTHOR.BOT?t('ctqp_title'):'User';
                const markdown = item.message
                return <div key={idx} className='pb-3'>
                  
                        <div className='d-flex align-items-center'>
                          <div style={{ borderRadius: '50%', width: '2em', overflow: 'hidden' }}>
                            <img src={logoAuthor} alt="" style={{ width: '2em'}} />
                          </div>
                          <b style={{marginLeft: '2em'}}>{nameAuthor}</b>
                        </div> 
                        <div style={{ padding: '0.5em 4em'}}>                          
                          {/* <div style={{ wordBreak: 'break-all'}} dangerouslySetInnerHTML={{__html: item.message}}></div> */}
                          <Markdown>{markdown}</Markdown>
                          <div className="mt-3" style={{ width: '100%', height: '2px', background: '#C5C5C5'}}></div>
                        </div>
                        
                      </div>
              })}
            </div>
            }
            

            <div className="w-100 text-center" style={{ position: 'absolute', bottom: '50px'}}>
              <div className={`ctqp-message-input-container ${(message.length>0||loading)&&false?'active':''}`}>
                <input className="ctqp-message-input" type="text" 
                  onChange={(e) => setMessage(e.target.value)} onKeyDown={(e) => onKeyDownMessage(e)}
                  placeholder={loading?'':t('placeholder')} value={message} />
                
                {loading &&
                <div style={{ position: 'absolute', left: '20px'}}>
                  <img src={threeDots} alt=""/>
                </div>
                }
                
                <div className='send-button' role="button" 
                  onClick={() => onSend(message)}>                    
                    <img src={sendingImg} alt="" height={40}></img>
                </div>

              </div>
            </div>
          </section>    
          <footer className='ctqp-footer'>
            <div>{t('terms_and_conditions')}</div>
            <div><a href="https://superreal.io" style={{ textDecoration: 'none', color: 'white'}}>{t('powered_by')}</a></div>
          </footer>
        
          <ToastContainer 
            position="bottom-right"
            transition={Slide} 
            autoClose={1000}
            closeButton={false}
            theme="colored"
            rtl />

        </main>
}


